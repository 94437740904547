<template>
  <div class="p-32">
    <analytics-dashboard />
  </div>
</template>

<script>
import AnalyticsDashboard from "@/view/main/dashboards/analytics";

export default {
  components: {
    AnalyticsDashboard,
  },
};
</script>
